<script>
  import screen from '@/helpers/screen'

  /*
   * The banned materials page
   */

  export default {
    name: 'EssentialsBannedMaterials',
    data() {
      return {
        standards: {
          heading: 'Our Standards/',
          subtitle:
            'Each and every product that goes into our essentials shop is thoughtfully curated, and made with love and care — we make sure of it. We vigorously vet our curations so you don’t have to.',
          items: [
            {
              title: '1. Respect for people.',
              body:
                'We are strongly committed to ensuring our products are made with respect for the fundamental dignity of workers.',
            },
            {
              title: '2. Respect for the planet.',
              body:
                'We push for better fibers and finishing processes in everything we deliver. There are zero hazardous chemicals and inputs in our curations.',
            },
            {
              title: '3. Honesty and transparency.',
              body:
                'All the products we carry are free from any ingredient found on our Clean Beauty Banned Ingredient List, one of the strictest in the industry.',
            },
            {
              title: '4. Good things, made well.',
              body: `Our product curators obsessively research and review thousands of products. Beautiful, clean, and safe are all musts in our market, but what good is all that if the product isn't effective? We make sure each product checks all the boxes.`,
            },
            {
              title: '5. No animals were harmed.',
              body:
                'Our animal neighbors deserve to be treated with love and respect. We see this as an integral part of sustainable living. All products in our curation are cruelty free.',
            },
          ],
        },
        banned: {
          heading: 'Skin Deep / Our Banned Ingredients List',
          subtitle: `Ingredients matter, and we believe what isn’t in a product is often as important as
            what is. We expect total transparency from brands on what’s in each product. Here is our
            ever-growing list of ingredients we exclude from our cosmetics and personal care
            products.`,
          items: [
            {
              title: '1,4 Dioxane',
              body:
                'Flammable ether and byproduct of some cosmetic manufacturing practices. A probable human carcinogen that one study found in over 1 in 3 lotions analyzed. Currently illegal and being phased out in New York.',
            },
            {
              title: 'Acrylates',
              body:
                'A synthetic ingredient found in acrylic nails. Classified as a possible human carcinogen. ',
            },
            {
              title: 'Aluminum Salts',
              body:
                'An ingredient found in antiperspirants. May be linked with breast cancer or Alzheimer’s.',
            },
            {
              title: 'Animal Oils, Musks, and Fats',
              body:
                'No animals are harmed in the making of these products. We opt for cruelty-free alternatives to ingredients sourced from animals. The only animal products we do allow in our skincare products are beeswax, honey, lanolin and carmine. ',
            },
            {
              title: 'Benzophenones and related compounds',
              body:
                'One of the chemicals flagged by Prop 65 in California, these are known to potentially cause cancer and disrupt your hormones. Often found in lip balm, shampoo, conditioner, and more. ',
            },
            {
              title: 'Butoxyethanol',
              body:
                'A solvent found in paint strippers, household cleaners, and, yep, cosmetics. May irritate skin or even cause cancer.',
            },
            {
              title: 'Butylated hydroxyanisole (BHA)',
              body:
                'A food additive that increases shelf life which the NIH says is "reasonably anticipated to be a human carcinogen.”',
            },
            {
              title: 'Butylated hydroxytoluene (BHT)',
              body:
                'An antioxidant and preservative that is likely a carcinogen, and may disrupt your hormones or lead to liver damage. ',
            },
            {
              title: 'Carbon Black or Black 2',
              body:
                'A color additive found in mascara, nail polish, lipstick, and other cosmetics. Listed as one of California Prop 65’s carcinogens.',
            },
            {
              title: 'Coal Tar',
              body:
                'Found in anti-dandruff shampoos and hair dye. Coal tar is a known carcinogen.  ',
            },
            {
              title: 'Cocamide diethanolamine (Cocamide DEA)',
              body:
                'A foaming agent found in hand soaps and shampoos. A possible carcinogen identified under California’s Prop 65. ',
            },
            {
              title: 'Ethanolamines',
              body:
                'Found in both personal care products and home cleaning products, this compound is linked to cancer and organ toxicity. Prohibited by the European Commission. ',
            },
            {
              title: 'Ethylenediaminetetraacetic acid (EDTA)',
              body:
                'A preservative in soaps and shampoos to prevent bacterial growth. May cause irritation.',
            },
            {
              title: 'Formaldehyde and Formaldehyde-releasing agents',
              body:
                'A preservative used to increase shelf-life. Most often found in nail care but also present in hair products. Banned by the EU as a carcinogen and suspected mutagenic.',
            },
            {
              title: 'Hydroquinone',
              body:
                'Chemical that bleaches skin. May be linked to cancer, compromised immune systems, and issues with adrenal glands.    ',
            },
            {
              title: 'Lead and Lead Acetate',
              body: 'Found in some hair dyes. Prohibited in Canada and the EU.  ',
            },
            {
              title: 'Mercury and Mercury Compounds (Thimerosal)',
              body:
                'A natural element found in most rocks and sediment that has been used as a preservative in cosmetics. Regulated by the FDA for its well documented toxicity. ',
            },
            {
              title: 'Methoxyethanol',
              body:
                'Ingredient found in nail polishes and other beauty products. Banned in the EU. May damage organs or harm the central nervous system.',
            },
            {
              title: 'Methyl Cellosolve and 2-Methoxyethanol',
              body: 'Same as above.',
            },
            {
              title: 'Methylchloroisothiazolinone and Methylisothiazolinone',
              body:
                'Preservative used to prevent bacteria growth. May cause skin irritation or allergic reactions. Banned in leave-in products in Canada and EU. ',
            },
            {
              title: 'Microbeads',
              body:
                'Small plastic beads often used for exfoliation in face washes. Contaminates water supplies. Banned in the US in 2019.  ',
            },
            {
              title: 'Mineral Oil',
              body:
                'aka petroleum jelly. Used to lock in moisture in both hair and skin care products. A potential carcinogen sourced from oil. ',
            },
            {
              title: 'Oxybenzone',
              body:
                'Ingredient found in many sunscreens responsible for harming coral reefs. Also under investigation for harmful effects on humans.',
            },
            {
              title: 'Parabens',
              body:
                'A category of preservatives that extends the life of many skincare and beauty products. May be linked to breast cancer and hormone disruption. ',
            },
            {
              title: 'Petrolatum and petrolatum byproducts',
              body:
                'A byproduct of petroleum production that is used to increase the shelf life of beauty products. Often contaminated in the manufacturing process with harmful chemicals.  We only accept products that use these ingredients if USP Grade.',
            },
            {
              title: 'Phthalates',
              body:
                'Chemicals that strengthen plastics, often found in hairspray, nail polish, and the bottles that hold our products. May be linked to cancer, diabetes, autism, and other health issues. ',
            },
            {
              title: 'Polyacrylamide and Acrylamide',
              body:
                'Found in many moisturizers and anti-aging serums as a binding and stabilizing agent. Possibly a carcinogen.   ',
            },
            {
              title: 'Resorcinol',
              body:
                'Often found in skin peels and hair dyes. Toxic in large doses. May disrupt hormones and cause skin sensitivity. ',
            },
            {
              title: 'Retinol and Retinyl Palmitate',
              body:
                'A blend of a fatty acid and vitamin A. May increase skin sensitivity and lead to an over absorption of vitamin A.  ',
            },
            {
              title: 'Styrene',
              body: 'Often found in sunscreen. Likely to be a carcinogen. ',
            },
            {
              title: 'Sulfates',
              body:
                'Also known as Sodium Lauryl Sulfate (SLS) and Sodium Laureth Sulfate (SLES). Found in shampoo and bodywash. Often triggers allergies and skin irritation. ',
            },
            {
              title: 'Talc',
              body:
                'The softest mineral in the world often found in powders and other cosmetics. Talc is frequently contaminated with asbestos, a known carcinogen. We only accept products that include talc if the supplier conducts testing to ensure it is asbestos-free.',
            },
            {
              title: 'Toluene',
              body: 'Often found in nail products. May cause neurotoxicity and skin irritation. ',
            },
            {
              title: 'Triclosan and Triclocarban',
              body:
                'Common antibacterial ingredients used in soaps. Identified by the FDA as not safe and effective in healthcare settings. May disrupt hormones and lead to antibiotic-resistant germs. ',
            },
          ],
        },
      }
    },
    computed: {
      screen,
    },
  }
</script>

<template>
  <div class="bg-dawn">
    <BaseContainer max-width="xl" class="py-4">
      <section class="my-8 md:mt-27 mb-8 md:mb-33">
        <h1
          class="border-b md:border-b-3 border-black pb-3 md:pb-8 mb-5 md:mb-16 font-heading font-light uppercase leading-none text-4xl md:text-8xl lg:text-10xl"
        >
          <BaseIcon class="inline-block mr-1 md:mr-0 mb-2 md:mb-5"
            ><IconLongArrowRight class="w-4 md:w-14"
          /></BaseIcon>
          What you won&apos;t<br class="md:hidden" />
          find in these<br />
          <div
            class="inline-flex absolute right-0 md:-mt-17 lg:-mt-26 -mr-4 md:mr-6 w-1/2 md:w-1/3"
          >
            <BaseImage
              src="/static/essentials/banned/EssentialsBannedPageIllustration1.png"
              alt=""
              background-transparent
              responsive="sm"
              sizes="50vw"
            />
          </div>
          products /<br />
          is just as<br class="md:hidden" />
          important<br class="md:hidden" />
          as what you will.
        </h1>
        <p class="md:font-semibold md:text-4xl leading-normal tracking-wide"
          >Each and every product that goes into our essentials shop is thoughtfully curated, and
          made with love and care — we make sure of it. We vigorously vet our curations so you don’t
          have to.</p
        >
      </section>

      <section class="flex">
        <BaseIcon class="mr-6 md:mr-14 mt-3 md:mt-9"
          ><IconLongArrowRight class="w-4 md:w-14"
        /></BaseIcon>

        <article class="md:text-4xl pr-7 lg:pr-25">
          <h2 class="font-heading text-xl md:text-9xl uppercase mt-2px md:mt-0 mb-3 md:mb-10">
            {{ standards.heading }}</h2
          >
          <p class="mb-5 md:mb-14">{{ standards.subtitle }}</p>
          <ul>
            <li v-for="(item, index) in standards.items" :key="index">
              <h4 class="font-semibold">{{ item.title }}</h4>
              <p>{{ item.body }}</p>
            </li>
          </ul>
        </article>
      </section>
      <section
        class="md:grid grid-cols-2 items-center -mx-6 md:px-0 md:ml-17 my-12 md:mt-10 md:mb-18"
      >
        <div>
          <BaseImage
            src="/static/essentials/banned/EssentialsBannedPage.png"
            alt=""
            background-transparent
            responsive="md"
            :sizes="{ default: '100vw', md: '50vw' }"
          />
        </div>
        <div>
          <BaseImage
            src="/static/essentials/banned/EssentialsBannedPageIllustration2.png"
            alt=""
            class="w-full h-auto"
            background-transparent
            responsive="md"
            :sizes="{ default: '100vw', md: '50vw' }"
        /></div>
      </section>

      <section class="flex mb-12 md:mb-38">
        <BaseIcon class="mr-6 md:mr-14 mt-3 md:mt-9"
          ><IconLongArrowRight class="w-4 md:w-14"
        /></BaseIcon>

        <article class="md:text-4xl pr-7 lg:pr-25">
          <h2 class="font-heading text-xl md:text-9xl uppercase mt-2px md:mt-0 mb-3 md:mb-10">
            {{ banned.heading }}</h2
          >
          <p class="mb-5 md:mb-14">{{ banned.subtitle }}</p>

          <ul>
            <li v-for="(item, index) in banned.items" :key="index">
              <h4 class="font-semibold">{{ item.title }}</h4>
              <p>{{ item.body }}</p>
            </li>
          </ul>
        </article>
      </section>
    </BaseContainer>
    <div class="flex items-center justify-center py-3 md:py-18">
      <BaseImage
        v-if="screen.xs"
        src="/static/essentials/banned/EssentialsBannedPageIllustration3.png"
        alt=""
        responsive="xl"
      />
      <BaseImage
        v-else
        src="/static/essentials/EssentialsIllustration.png"
        alt=""
        responsive="sm"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  article ul li {
    @apply mb-5;
  }

  @screen md {
    article ul li {
      @apply mb-12;
    }
  }
</style>
